import classNames from "classnames";
import React from "react";
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import userIcon from 'assets/images/account-page/user-icon.svg'
import css from "./style.scss";
import accountCSS from "../style.scss";

const ChooseTier = (props) => {
    return (
        <>
            <div className="container">
                <h2 className={accountCSS.clsMainTitle}>Choose a Tier for Your New Team Member</h2>

                <div className="row justify-content-center">
                    <div className={`col-auto ${css.pdSmall}`}>
                        <div className={css.clsTMCard}>
                            <div className={css.clsSection1}>
                                <ReactSVG src={userIcon} className={css.clsUserIcon} />
                                <h4 className={css.clsTxt1}>Scout</h4>
                                <p className={css.clsTxt2}>Limited Mobile-Only Access</p>
                            </div>
                            <div className={css.clsSection2}>
                                <h4 className={css.clsTxt1}>FREE</h4>
                                <button className={classNames(accountCSS.btn_primary, accountCSS.clsBtnOrng)}> Choose Tier and Continue</button>
                            </div>
                            <div className={css.clsSection3}>
                                <h4 className={css.clsTxt1}>Features:</h4>
                                <ul className={css.clsFeatureList}>
                                    <li>
                                        Save potential leads on the mobile App
                                    </li>
                                    <li>
                                        Use Drive Feature on Mobile App
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={`col-auto ${css.pdSmall}`}>
                        <div className={css.clsTMCard}>
                            <div className={css.clsSection1}>
                                <ReactSVG src={userIcon} className={classNames(css.clsUserIcon, css.clsUserIconGreen)}
                                  beforeInjection={(svg) => {
                                    svg.querySelectorAll('path').forEach((path) => {
                                        path.setAttribute('fill', '#128292'); // Set the color for all paths
                                    });
                                  }} 
                                />
                                <h4 className={classNames(css.clsTxt1, css.text_primary)}>Full Access</h4>
                                <p className={css.clsTxt2}>Full Access</p>
                            </div>
                            <div className={css.clsSection2}>
                                <h4 className={css.clsTxt1}>$30<sub>/mo</sub></h4>
                                <button className={classNames(accountCSS.btn_primary, accountCSS.clsBtnOrng)}> Choose Tier and Continue</button>
                            </div>
                            <div className={css.clsSection3}>
                                <h4 className={css.clsTxt1}>Features:</h4>
                                <ul className={css.clsFeatureList}>
                                    <li>
                                        Full Access to All of the same PropStream Features as the account owner
                                    </li>
                                    <li>
                                        Learn More
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};


export default withRouter(ChooseTier);
