import classNames from "classnames";
import React from "react";
import { withRouter } from 'react-router-dom';
import css from '../style.scss';
import groupIocn from 'assets/images/account-page/group-iocn.svg';
import userIcon from 'assets/images/account-page/user-icon.png';
import infoDanger from 'assets/images/account-page/info-danger.svg'; 
import bannerImg from 'assets/images/account-page/team-card-banner.png'
import { ReactSVG } from 'react-svg';
import threeDot from 'assets/images/account-page/three-dot-icon.svg'


const TeamSection = (props) => {

  return (
    <div className={classNames(css.clsCard, css.clsTeamCard)}>
      <div className={classNames(css.clsCardHeader, css.d_flex, css.align_items_center, css.justify_content_between)}>
        <h4 className={classNames(css.clsCardTitle)}>Team</h4>

        <div className={css.clsInfoPopup}>
          <a href="javascript:;" onClick={() => props.history.push(`/accountnew/team`)} className={classNames(css.clsCardTxt, css.text_danger)}>
          Finish Building Your Team
          <span className={css.clsInfoIcon}><img src={infoDanger} alt="info" /></span>
          </a>
           
            {/* <div className={classNames(css.clsInfoPopupContent, css.clsOpenPopup, css.clsInfoPopupRight)}>
              <h5 className={css.clsInfoContentTitle}>Spending Remaining <span className={css.clsCloseIcon}>  &times;   </span></h5>
              <p className={css.clsInfoContentPara}>PropStream’s Pro Plan offers access for up to two team members, with the option to add three more for an additional charge per spot. The Elite Plan provides access for up to nine members via prepaid slots.</p>
            </div>   */}
        </div>
      </div>

    <div className={css.clsCardBody}>
      <div className={classNames(css.d_flex, css.align_items_center, css.justify_content_between)}>
        <ul className={classNames(css.clsTeamList, css.unstyled)}>
          <li className={css.clsBorderRight}>
            <div className={css.clsTeamContent}>
              <img src={groupIocn} className={css.mr_5px} />
              <div className={css.clsTeamContentTxt}>
                <h5 className={css.clsTeamContent1}>
                  Full-Access
                </h5>
                <h4 className={css.clsTeamContent2}>1</h4>
              </div>
            </div>
          </li>
          <li>
            <div className={css.clsTeamContent}>
              <div className={css.clsTeamContentTxt}>
                <h5 className={css.clsTeamContent1}>
                  Scouts
                </h5>
                <h4 className={css.clsTeamContent2}>5</h4>
              </div>
            </div>
          </li>
        </ul>
        <div className={css.clearfix}>
          <button className={classNames(css.btn_primary, css.clsBtnPrimaryGreen)}>Add To Team</button>
        </div>
      </div>

      <div className={classNames(css.pt_20, "table-responsive", css.responsivetable)}>
        <table className={classNames(css.clsTable, css.clsTableBgGray, "table")}>
          <thead>
            <tr>
              <th>Member</th>
              <th>Type</th>
              <th>Exports</th>
              <th>Spending</th>
              <th></th>
            </tr>
          </thead>
          <tbody> 
            <tr>
              <td>
                <div className={classNames(css.d_flex, css.align_items_center)}>
                <img src={userIcon} alt="User" width={30} height={30} className={css.mr_5px} />
                  <span>Jordan Shilkoff</span>
                </div>
              </td>
              <td>
                Full Access
              </td>
              <td>
                <div>200,000</div>
                <div className={classNames(css.text_gray, css.text_12, css.text_m, css.mt_5px)}>/200,000</div>
              </td>
              <td>
                <div>200,000</div>
                <div className={classNames(css.text_gray, css.text_12, css.text_m, css.mt_5px)}>/200,000</div>
              </td>
              <td>
                <div className={css.clsTableActionBtn}>
                  <ReactSVG src={threeDot} className={css.clsActionIcon}/>

                   <ul className={classNames(css.clsActionDropdown, css.unstyled)}>
                    <li>
                      <a href="javascript:;">Edit Profile</a>
                    </li>
                    <li>
                      <a href="javascript:;">Set Save/Export Limits</a>
                    </li>
                    <li>
                      <a href="javascript:;">Upgrade to Full-Access</a>
                    </li>
                    <li>
                      <a href="javascript:;">Downgrade to Scout</a>
                    </li>
                    <li>
                      <a href="javascript:;">Remove Team Member</a>
                    </li>
                    <li>
                      <a href="javascript:;">Review Pending Changes</a>
                    </li>
                    </ul> 
                </div>
              </td>
            </tr>
            <tr>
              <td>
              <div className={classNames(css.d_flex, css.align_items_center)}>
                <img src={userIcon} alt="User" width={30} height={30} className={css.mr_5px} />
                  <div className={css.clsTableTxtAlrt}>
                  <span  className={css.clsTableTxt}>  Michael LeVoir </span>
                    <span className={classNames(css.clsBtnSm, css.clsBtnDanger)}>Expiring Soon</span></div>
                </div>
              </td>
              <td>
                Full Access
              </td>
              <td>
                <div>200,000</div>
                <div className={classNames(css.text_gray, css.text_12, css.text_m, css.mt_5px)}>/200,000</div>
              </td>
              <td>
                <div>200,000</div>
                <div className={classNames(css.text_gray, css.text_12, css.text_m, css.mt_5px)}>/200,000</div>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
              <div className={classNames(css.d_flex, css.align_items_center)}>
                <img src={userIcon} alt="User" width={30} height={30} className={css.mr_5px} />
                  <span>Nicole Fortunaso</span>
                </div>
              </td>
              <td>
              Scout
              </td>
              <td>
                N/A
              </td>
              <td>
              N/A
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

        {/* <div className={css.clsTeamCardBanner}>
          <div className={css.clsBannerImg}>
            <img src={bannerImg} alt='banner' />
          </div>
          <div className={css.clsBannerContent}>
            <div className={classNames(css.pb_35, "clearfix w-100")}>
              <p className={css.clsBannerPara}>Add team members to enhance collaboration and your property search strategies.</p>
            </div>
            <button className={classNames(css.btn_primary, css.clsBtnWhite, css.text_primary)}>Create Your Team Today</button>
          </div>
        </div> */}
    </div>
  </div>
  );
};

export default withRouter(TeamSection);
