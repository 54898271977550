import React, { useRef } from "react";
import css from '../style.scss';

const UploadProfilePicture = ({ filed, setFieldValue, setImageUrl }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Extract the Base64 string (exclude the "data:image/*;base64," prefix)
        
        // Update the marketingProfilePicture field in Formik with Base64
        setFieldValue(filed, base64String);

        // Set the image URL for previewing the uploaded image
        setImageUrl(base64String); // This includes the prefix and can be used as a source for an <img> tag
      };

      reader.readAsDataURL(file); // Read the file as a Base64 string
    }
  };

  return (
    <div>
      <a href="javascript:;" className={css.clsAddTxt} onClick={handleClick}>
        Add Photo
      </a>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*" // Ensures only image files can be selected
        onChange={handleFileChange}
      />
    </div>
  );
};

export default UploadProfilePicture;